.attachment-icon {
    display: none;
  }
  /* .custom-message-list {
    overflow-y: hidden; 
    height: 400px;
  } */
.list-group-item{
  cursor: pointer;
}

.list-group-item:hover{
  font-weight: 700;
}
  /* height: 400px; Adjust the height to your preferred value */
  @media print {
    .col-md-4 {
      display: none;
    }
    .col-md-8{
      flex: 0 0 100%;
      max-width: 100%;
    }
    .print_button{
      display: none;
    }
    .MessageInput{
      display: none;
    }
    .title{
      font-size: 26px !important;
    }
    .quotaMessage{
      display: none;
    }
    .App{
      height: auto !important;
    }
    .cs-main-container {
      border:none;
    }
  }
  